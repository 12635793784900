// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layouts-post-layout-js": () => import("./../../../src/components/layouts/PostLayout.js" /* webpackChunkName: "component---src-components-layouts-post-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bws-js": () => import("./../../../src/pages/bws.js" /* webpackChunkName: "component---src-pages-bws-js" */),
  "component---src-pages-cbd-js": () => import("./../../../src/pages/cbd.js" /* webpackChunkName: "component---src-pages-cbd-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-md": () => import("./../../../src/pages/privacy.md" /* webpackChunkName: "component---src-pages-privacy-md" */),
  "component---src-pages-qr-js": () => import("./../../../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-pages-terms-md": () => import("./../../../src/pages/terms.md" /* webpackChunkName: "component---src-pages-terms-md" */),
  "component---src-pages-verified-js": () => import("./../../../src/pages/verified.js" /* webpackChunkName: "component---src-pages-verified-js" */)
}

